import React from 'react';

const NewsTicker = () => {
return (
<>
<div className='newTick'>
<div className='col'><span>New Offer</span></div>
<div className='col'><span className='newsTxt'>create account and get instant 10% discount. india's largest gaming site. cricket, soccer, tennis, casino, virtual casino and varoius types of games are available at one platform..Play Real And Win Real..</span></div>
</div>
</>
)
}

export default NewsTicker;
