import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewsTicker from "./extra/NewsTicker";
import Header from "./extra/Header";
import Home from "./routes/Home";

function App() {
return (
<BrowserRouter>
<NewsTicker />
<Header />
<Routes>
<Route path='/' element={<Home />} />
</Routes>
</BrowserRouter>
);
}

export default App;
