import React from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
return (
<>
<div className='header'>
<div className='row'>
<div className='col'>
<div className='logo'>
<Link to='/'><img src="/images/logo.png" alt='logo' /></Link>
</div>
</div>
<div className='col'>
<div className='whatsappHead'>
<div className='col'><i className='fa fa-whatsapp iconWhat'></i></div>
<div className='col'>
<Link to='https:wa.me/+441264980998'><span className='number-mobile'>+44 1264 98 0998</span></Link>
<Link to='https:wa.me/+441264980985'><span className='number-mobile'>+44 1264 98 0985</span></Link>
</div>
</div>

<div className='whatsappHeadSingle'>
<div className='col'><i className='fa fa-whatsapp iconWhat2'></i></div>
<div className='col'>
<Link to='https://wa.me/+441253670454'><span className='number-mobile'>+44 1253 67 0454</span></Link>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Header;
