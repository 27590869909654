import React from 'react';
import ContactUs from '../extra/ContactUs';
import { Link } from 'react-router-dom';

const Home = () => {

const date = new Date();

return (
<>
<div className='body-content'>
<div className='container'>
<div className='imagesHead'>
<img src='/images/football.png' alt='imagesHead1' className='player1'/>
<img src='/images/pokerChips.png' alt='imagesHead1' className='player1'/>
<img src='/images/cricket.png' alt='imagesHead1' className='player1'/>
</div>

<div className='content1'>
<h1>asia's biggest betting service provider</h1>
<button><Link to='https://wa.me/+441253670454'><i className='fa fa-whatsapp'></i> get bet id now</Link></button>
</div>

<div className='contentScroll'>
<h1>We have a betting platform with 300+ games and website to play and bet on different type of subjects and games.</h1>
</div>

<div className='content2'>
<p>We provide various betting exchanges with more than 300+ Platforms to play on different types of sports , games and casinos. We Provide all the levels of IDs, e.g: Admin , Master, Player ID. We Provide White Panels of all Websites.</p>
</div>

<div className='content3'>
<h1 className='titleHead'>exchanges available</h1>
<hr className='titleHr' />
<button className='skewBtn skewRed'><Link to='http://asstarwin.com'><span className='skewTxt'>www.asstarwin.com</span></Link></button>
<button className='skewBtn skewRed'><Link to='http://betas247.com'><span className='skewTxt'>www.betas247.com</span></Link></button>
<button className='skewBtn skewRed'><Link to='http://asexch365.live'><span className='skewTxt'>www.asexch365.live</span></Link></button>
<button className='skewBtn skewRed'><Link to='http://skyexch.art'><span className='skewTxt'>www.skyexch.art</span></Link></button>
</div>

<div className='content3'>
<h1 className='titleHead'>check our demo betting id</h1>
<hr className='titleHr' />

<div className='demoBox'>
<h3>try demo1</h3>
<table>
<thead>
<tr>
<th colSpan='2'>www.asstar.win</th>
</tr>
</thead>
<tbody>
<tr>
<td>username :</td>
<td>newdemo1</td>
</tr>
<tr>
<td>password :</td>
<td>abcd1234</td>
</tr>
</tbody>
</table>

<h3>try demo2</h3>
<table>
<thead>
<tr>
<th colSpan='2'>www.asstar.win</th>
</tr>
</thead>
<tbody>
<tr>
<td>username :</td>
<td>newdemo2</td>
</tr>
<tr>
<td>password :</td>
<td>abcd1234</td>
</tr>
</tbody>
</table>
</div>
</div>

<div className='content4'>
<h1 className='titleHead'>get online sports betting id</h1>
<hr className='titleHr' />
<p>you can only get the Most Premium Sports Online Cricket IDs at As Group. Online Betting IDs that are 100% genuine and secure, with 24-hour customer service. We are here to offer you a fantastic betting experience in a professional setting. With our instant ID creation service, you can enjoy the benefits of our platform which provide you with a wide range of Betting ID options. Choose from a large group of trustable and secure sports betting websites in India As smartphone betting becomes more popular, the gambling market is moving online. From poker to snooker, horse racing to motor sports, Online Betting ID has emerged as one of the most interesting and valuable online trends. This website helped you to provide step-by-step advice and guidance for the best Bet ID.</p>
</div>

<div className='content4'>
<h1 className='titleHead'>payments we accept or deposit/withdrawl</h1>
<hr className='titleHr' />

<div className='scrollImages'>
<img src='/images/imps.png' alt='scoll-img' />
<img src='/images/gpay.png' alt='scoll-img' />
<img src='/images/paytm.png' alt='scoll-img' />
<img src='/images/pPay.png' alt='scoll-img' />
</div>
</div>

<div className='content3'>
<h1 className='titleHead'>get in touch</h1>
<hr className='titleHr' />
<button className='skewBtn skewFlex skewPin'>
<img src='/images/whatsapp.png' alt='social-icon' className='iconSkew' />
<Link to='https://wa.me/+441253670454'> 
<span className='skewTxtF'>whatsapp</span> 
<span className='skewTxtL'>+44 1253 67 0454</span>
</Link>
</button>
<button className='skewBtn skewFlex skewPin'>
<img src='/images/instagram.png' alt='social-icon' className='iconSkew' /> 
<Link to='https://instagram.com/asstarwin'>
<span className='skewTxtF'>instagram</span> 
<span className='skewTxtL'>instagram.com/asstarwin/</span>
</Link>
</button>
<button className='skewBtn skewFlex skewPin'>
<img src='/images/telegram.png' alt='social-icon' className='iconSkew' /> 
<Link to='https://t.me/asstarwin'>
<span className='skewTxtF'>telegram</span> 
<span className='skewTxtL'>t.me/asstarwin</span>
</Link>
</button>
<button className='skewBtn skewFlex skewPin'>
<img src='/images/gmail.png' alt='social-icon' className='iconSkew' /> 
<Link to='https://gmail.com'>
<span className='skewTxtF'>gmail</span> 
<span className='skewTxtL'>asexch98@gmail.com</span>
</Link>
</button>
</div>

<div className='content5'>
<h1 className='titleHead'>send message</h1>
<hr className='titleHr' />
<ContactUs />
</div>

<div className='copyRight'><span>© copyright {date.getFullYear()} asgroup. All rights reserved.</span></div>

</div>
</div>
</>
)
}

export default Home;
