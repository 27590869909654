import React, { useRef } from 'react';
import $ from 'jquery';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
const form = useRef();

const sendEmail = (e) => {
var user = $('#userEmail').val();
var email = $('#emailEmail').val();
var msgs = $('#emailMsg').val();
e.preventDefault();

console.log(user +' -- '+ email +' -- '+ msgs);
if(user === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('your name required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else if(email === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('your email required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else if(msgs === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('your message required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{

emailjs.sendForm('service_ye8nw4r', 'template_n0ild67', form.current, {
publicKey: 'v8StnJHjt0Z1fWetX',
})
.then(
() => {
//console.log('SUCCESS!');
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('email sent successfully');
setTimeout(function(){
$('#msgAlert').hide();
$('.contactUs')[0].reset();
},1000);
return false;
},
(error) => {
//console.log('FAILED...', error.text);
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('email sent error');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
},
);
}
};

return (
<form ref={form} onSubmit={sendEmail} className='contactUs'>
<label>Name :</label>
<input type="text" name="user_name" placeholder='Your Name...' id='userEmail' />
<label>Email :</label>
<input type="email" name="user_email" placeholder='Your Email...' id='emailEmail' />
<label>Message :</label>
<textarea name="message" rows='8' placeholder='Your Message...' id='emailMsg' />
<button type="submit" className='emailSend'><i className='fa fa-paper-plane'></i> <span>Send Message</span></button>
</form>
);
};

export default ContactUs;